#button-component{
    color: var(--primary) ;
    background-color: white ;
    border-radius: 0 !important;
    transition: background-color 200ms ease-in-out,scale 200ms ease-in-out ;
    border: solid 1px var(--primary);
    border-radius: 4px !important;
}

.full{
    background-color: var(--primary) !important;
    color: white !important;
}

#button-component:hover{
    background-color: var(--primary) !important;
    color: white !important;
    scale: 1.1;
}
