#features{
    background-color: var(--primary);
    transform: translateY(-40%);
}

#features > div {
    gap: 10px;
}

#features button {
    gap: 10px;
    background-color: var(--black-01);
}

/* #features p {
    color: black;
    font-size: 1.5rem;
} */

#features p:last-child{
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--gray-01);
}
