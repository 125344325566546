:root {
  --primary: #ED1D22;
  --secundary: #006D77;
  --red-01: #e97171;
  --red-02: #ad1f28;
  --green-01: #2ec1ac;
  --gray-01: #898989;
  --gray-02: #F4F5F7;
  --gray-03: #9F9F9F;
  --gray-04: #D9D9D9;
  --gray-05: #F0F1F7;
  --black-01: #222222;
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Oxanium", sans-serif !important;
  /* background: rgb(127, 3, 127);
  background: linear-gradient(0deg, rgba(127, 3, 127, 1) 0%, rgba(43, 0, 39, 1) 100%); */
  scroll-behavior: smooth;
}

span{
  font-family: "Poppins", sans-serif !important;
}

main{
  /* padding: 1rem 2rem; */
  min-height: calc(100vh - 7rem)
}

/* @media only screen and (max-width: 600px) {
  main {
    padding: 0.5rem 1rem; 
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  main {
    padding: 0.75rem 1.5rem; 
  }
} */

.primary{
  color: white !important;
  background: var(--primary) !important;
}

.secundary{
  color: white !important;
  background: var(--secundary) !important;
}

.text-feature{
  font-weight: 600;
  font-size: 1rem;
  color: var(--secundary);
}
.text-feature-red{
  font-weight: 600;
  font-size: 1rem;
  color: var(--primary);
}

.text-primary{
  font-weight: 600;
  font-size: 1rem;
  color: var(--primary);
}

.text-secundary{
  font-size: 1rem;
  color: var(--gray-03);
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  background: linear-gradient(to right, #04103d84, transparent);
}

.link-button {
  border: solid 1px white;
  text-align: center;
  text-decoration: none !important;
  background: none;
  width: 80%;
  color: white !important;
  padding: 0.5rem 1rem !important;
}

.link-button:hover {
  background: var(--gray-02);
  color: var(--primary) !important;
}

/* Estilo para navegadores baseados no WebKit */
::-webkit-scrollbar {
  width: 12px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background-color: var(--black-01); /* Cor de fundo da área de rastreamento */
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary); /* Cor do botão de rolagem */
  border-radius: 6px; /* Borda arredondada do botão de rolagem */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Cor do botão de rolagem ao passar o mouse sobre ele */
}

/* Estilo para navegadores baseados no Mozilla */
::-moz-scrollbar {
  width: 12px; /* Largura da barra de rolagem */
}

::-moz-scrollbar-track {
  background-color: var(--black-01); /* Cor de fundo da área de rastreamento */
}

::-moz-scrollbar-thumb {
  background-color: var(--primary); /* Cor do botão de rolagem */
  border-radius: 6px; /* Borda arredondada do botão de rolagem */
}

::-moz-scrollbar-thumb:hover {
  background-color: var(--red-02); /* Cor do botão de rolagem ao passar o mouse sobre ele */
}
