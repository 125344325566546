#pagFooter {
    width: 100%;
    background-color: var(--black-01);
    color: white;
}

#copyright-holder svg{
    cursor: pointer;
}

.copyright{
    font-family: "Poppins", sans-serif !important;
}

.footer-links span {
    cursor: pointer;
}

#newsletter-button {
    border-bottom: 2px solid black;
    cursor: pointer;
    padding-bottom: 0.5rem;
    margin-top: 0.5rem;
    /* text-decoration: underline; */
    /* line-height: 2rem; */
}

#border{
    border-top: solid 1px rgba(255, 255, 255, 0.15);
}
